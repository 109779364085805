/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withPrefix, graphql } from 'gatsby';

// Layouts
import Default from '../layouts/default';
import Seo from '../layouts/seo';

// Component
import PageHeader from '../components/page-header';
import Cards from '../components/cards';
import Section from '../components/section';

const Property = (props) => {
    const { pageData, tools, articles } = props.data;
    const headerImage = pageData.frontmatter.image.relativePath;

    const filteredTools = tools?.nodes && tools.nodes.filter(tool => tool.frontmatter.category === 'Property');
    const sortedTools = filteredTools.sort((a, b) => a.frontmatter.featured ? -1 : b.frontmatter.featured ? 1 : 0);

    const latestArticles = articles.edges;

    return (
        <Default>
            <Seo
                title={pageData.frontmatter.title}
                description={pageData.frontmatter.description}
            />
			
			<PageHeader
				heading={pageData.frontmatter.title}
				imageSource={`${withPrefix("/")}img/${headerImage}`}
				description={pageData.frontmatter.description}
				breadcrumbs={[
                    {name: 'Home', url: '/'},
                ]}
			/>
			
            <Section
                heading='Our tools'
                introduction='All of our calculators and tools have been designed to be as simple as possible for you to use - get started by clicking on any of the options below'
                variants={['padded']}
            >
               <Cards>
                    {sortedTools.map((tool, index) => {
                        if (tool.frontmatter.disabled) return null;
                        
                        const toolImage = tool.frontmatter.image.relativePath;

                        return (
                            <Cards.Item
                                variant='bordered'
                                destination={`/property/${tool.frontmatter.path}`}
                                image={`${withPrefix("/")}img/${toolImage}`}
                                isNew={tool.frontmatter.new}
                                isFeatured={tool.frontmatter.featured}
                                key={index}
                            >
                                {tool.frontmatter.title}
                            </Cards.Item>
                        );
                    })}
               </Cards>
            </Section>

            {!!latestArticles.length && (
                <Section
                    heading='Latest articles'
                    introduction='Read some of our latest property-related articles'
                    variants={['padded', 'secondary']}
                >
                    <Cards>
                        {latestArticles.map((article, index) => {
                            const articleImage = article.node.frontmatter.image.relativePath;

                            return (
                                <Cards.Item
                                    destination={`/articles/${article.node.frontmatter.path}`}
                                    image={`${withPrefix("/")}img/${articleImage}`}
                                    isNew={article.node.frontmatter.new}
                                    isFeatured={article.node.frontmatter.featured}
                                    key={index}
                                >
                                    {article.node.frontmatter.title}
                                </Cards.Item>
                            );
                        })}
                    </Cards>
                </Section>
            )}
        </Default>
    );
};

export default Property;

export const PropertyQuery = graphql`
  query Property($id: String!) {
    pageData: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image {
            relativePath
        }
        description
      }
    }
    tools: allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/src/tool-info/property/"}},
        limit: 2000,
        sort: {fields: [frontmatter___new], order: DESC},
    ) {
        nodes {
            frontmatter {
                path
                featured
                new
                tags
                title
                disabled
                description
                disabled
                image {
                    relativePath
                }
                category
            }
        }
    }
    articles: allMarkdownRemark(
        sort: {order: DESC, fields: [frontmatter___date]}
        filter: {frontmatter: {templateKey: {eq: "blog-post"}, tags: {in: "Property"}}}
      ) {
        edges {
          node {
            excerpt(pruneLength: 400)
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              author
              tags
              path
              templateKey
              image {
                relativePath
              }
            }
          }
        }
      }
  }
`;